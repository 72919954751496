import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-79cc8410"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dj-tabs"
};
var _hoisted_2 = {
  class: "dj-tabs-head"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "dj-tab-title"
};
var _hoisted_5 = {
  class: "dj-tabs-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: _normalizeClass(['dj-tab', _ctx.isactive == index ? 'is-active' : '']),
      style: _normalizeStyle({
        width: _ctx.width(_ctx.title.length) + '%'
      }),
      onClick: function onClick($event) {
        return _ctx.tabchange(index);
      }
    }, [_createElementVNode("span", _hoisted_4, _toDisplayString(item), 1), _renderSlot(_ctx.$slots, "decTit", {
      item: {
        index: index,
        item: item
      }
    }, undefined, true)], 14, _hoisted_3);
  }), 128))]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.title, function (item, index) {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      key: index,
      class: "dj-tab-pane"
    }, [_renderSlot(_ctx.$slots, "content".concat(index), {}, undefined, true)], 512)), [[_vShow, _ctx.isactive == index]]);
  }), 128))])]);
}