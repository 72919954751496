import { computed, defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    title: {
      required: true,
      type: Array
    }
  },
  emits: ['changeOther'],
  setup: function setup(prop, context) {
    //点击切换
    var isactive = ref(0);

    function tabchange(val) {
      isactive.value = val;
      context.emit("changeOther", val);
    } //计算宽度


    var width = computed(function () {
      return function (num) {
        return 100 / num;
      };
    });
    return {
      tabchange: tabchange,
      isactive: isactive,
      width: width
    };
  }
});